import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Box, Button, Drawer, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { IGetReleasesResponse, IPostReleasesRequest } from '../../shared/services/models/release';
import releasesRepository from '../../shared/services/repositories/releases_repository';

export interface IReleaseDrawerProps {
    open: boolean,
    deposit_id: number,
    product_id: number,
    handleDrawerToggle: () => void,
    onCreate: (release:IGetReleasesResponse)=>void,
}

export const ReleaseDrawer: React.FC<IReleaseDrawerProps> = (props) => {

    const initialFormValues = useMemo<IPostReleasesRequest>(() => ({
        qty: 0,
        observation: '',
        product_id: props.product_id,

    }), [props.deposit_id, props.product_id])

    const [formLoading, setFormLoading] = useState(false);
    const [formValues, setFormValues] = useState<IPostReleasesRequest>(initialFormValues)
    const [formErrors, setFormErrors] = useState()

    function handleSubmit() {

        setFormLoading(true)
        const data = {...formValues, product_id: props.product_id}
        releasesRepository
            .store(data)
            .then(res => {
                console.log("lancamentos _>",res)
                props.onCreate(res.data as IGetReleasesResponse)
                props.handleDrawerToggle();
                cleanFields();
            })
            .catch(err => {
                console.log("lancamentos _>",err)
                alert(err.message)
                setFormErrors(err.data)
            })
            .finally(() => {
                setFormLoading(false)
            })
    }

    function cleanFields(){
        const fileds = {
            qty: 0,
            observation: '',
            product_id: props.product_id,
        }

        setFormValues(fileds)
    }

    return (
        <Drawer
            anchor='right'
            open={props.open}
            sx={{
                overflowX: 'hidden',
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: { xs: '100%', md: 600 }
                },
            }}
        >
            <Box sx={{ p: 3 }}>
                <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
                    <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
                        <Typography variant='h6'>
                            Nome do produto
                        </Typography>
                        <Typography variant='body2'>
                            variação
                        </Typography>
                    </Stack>
                    <Button endIcon={<CloseIcon />} size={'small'} onClick={props.handleDrawerToggle}>
                        Fechar
                    </Button>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DatePicker />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Qtd'
                            type='number'
                            variant='filled'
                            fullWidth
                            value={formValues.qty}
                            onChange={e => setFormValues(old => ({ ...old, qty: Number(e.target.value) }))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            minRows={3}
                            label='Observação'
                            type='text'
                            variant='filled'
                            fullWidth
                            value={formValues.observation}
                            onChange={e => setFormValues(old => ({ ...old, observation: e.target.value }))}
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <LoadingButton
                        variant='contained'
                        sx={{ borderRadius: 0 }}
                        disabled = {formValues.qty == 0}
                        startIcon={<SaveIcon />}
                        loading={formLoading}
                        onClick={handleSubmit}
                    >
                        salvar
                    </LoadingButton>
                    <Button                
                        sx={{ borderRadius: 0, mr: 2 }}
                        onClick={e => setFormValues(initialFormValues)}
                    >
                        Limpar
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};
