
import { IAuthModel } from "../services/models/auth";
import { useState, createContext,useEffect } from 'react'
import authRepository from "../services/repositories/auth_repository";
import SplashScrean from "../../pages/splashScrean";

type authModel = IAuthModel | undefined

interface IAuthContextProps {
    auth?: IAuthModel,
    setAuth: (auth?: IAuthModel) => void
}

interface IAuthContextProviderProps {
    children: React.ReactNode
}

export const authContext = createContext({} as IAuthContextProps)
export const AuthContextProvider = (props: IAuthContextProviderProps) => {

    const [auth, setAuth] = useState<authModel>(undefined)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        authRepository
            .getUser()
            .then(res => {
                console.log("login", res)
                setAuth(old => ({ ...old, user: res.data }))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    function handleSetAuth(auth?: IAuthModel) {
        setAuth(auth)
    }

    if (loading)
        return <SplashScrean />

    return (
        <authContext.Provider
            value={{ auth: auth, setAuth: handleSetAuth }}
        >
            {props.children}
        </authContext.Provider>
    )
}


