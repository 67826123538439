import LockIcon from '@mui/icons-material/Lock'
import { Box, Button, Grid, InputAdornment, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteHelper } from '../../helpers/route_helper'
import authRepository from '../../shared/services/repositories/auth_repository'
import { useState } from 'react'
import { AppConst } from '../../constants/app_constants'
import { LoadingButton } from '@mui/lab'
import { ILoginPost } from '../../shared/services/models/auth'
import { useAuth } from '../../shared/hooks/useAuth'

const loginValues = {
    username: '',
    password: '',
}

interface IErrorsValues {
    username?: string,
    password?: string,
    message?: string,
}

const LoginPage = () => {

    const navigate = useNavigate()
    const [fields, setFields] = useState<ILoginPost>(loginValues)
    const [errors, setErrors] = useState<IErrorsValues | undefined>({})
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const { setAuth } = useAuth()

    function handleLogin() {

        setLoading(true)

        authRepository
            .login(fields)
            .then(res => {
                console.log("logon-1", res.data)
                setAuth(res.data)
                //guarda o token
                localStorage.setItem(AppConst.name, res.data.token as string)

                //redireciona para a pagina pretendida
                if (location.state) {
                    navigate(location.state)
                } else {
                    navigate(RouteHelper.home, { replace: true })
                }

            })
            .catch(err => {
                if(err.data?.message){
                    alert(err.data.message);
                }
                console.log(err)
                setErrors(err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            {/* <AppBarTop drawerProps={{ open: false, width: 0, handleDrawerToggle: () => {} }} /> */}
            {/* <Toolbar /> */}
            <Box sx={{ width: '100%', minHeight: '100vh' }} >
                <Toolbar />
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10} md={7} lg={4} >
                        <Paper
                            sx={{ height: '100%', py: 4, px: 3 }}
                        >
                            <Stack direction='column' spacing={2}>
                                <Stack direction='column' spacing={2} alignItems={'center'} width={'100%'}>
                                    <Typography variant='h4' color={'GrayText'}>
                                        Gueva Admin
                                    </Typography>
                                    <Typography variant='h5' color={'GrayText'}>
                                        Entrar na aplição
                                    </Typography>
                                </Stack>
                                <TextField
                                    value={fields.username}
                                    onChange={e => setFields(old => ({ ...old, username: e.target.value }))}
                                    label='username'
                                    error={errors?.username !== undefined}
                                    helperText={errors?.username}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='start'>@</InputAdornment>
                                    }}
                                />
                                <TextField
                                    value={fields.password}
                                    onChange={e => setFields(old => ({ ...old, password: e.target.value }))}
                                    label='Password'
                                    type='password'
                                    error={errors?.password !== undefined}
                                    helperText={errors?.password}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='start'><LockIcon /></InputAdornment>
                                    }}
                                />
                                <Box>{errors?.message}</Box>
                            </Stack>
                            <Stack justifyItems={'center'}>
                                <Box>
                                    <Button size='small' onClick={()=>navigate(RouteHelper.register)}>
                                        Não tens uma conta? Registar
                                    </Button>
                                </Box>
                            </Stack>
                            <LoadingButton
                                sx={{ mt: 2, borderRadius: '0' }}
                                variant='contained'
                                onClick={handleLogin}
                                loading={loading}
                            >
                                Entrar
                            </LoadingButton>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default LoginPage