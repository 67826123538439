import { ApiConstants } from "../../../constants/api_constants"
import axiosInstance from "../../api/api_client"
import { IPostProductRequest, IPutProductStatus } from "../models/product"

const getAll = async (page: number, noPag?: boolean, query: string = "", active: number = 0) => {
    await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: ApiConstants.baseUrl })
    let response = await axiosInstance.get(`/products${noPag ? '?paginating=false' : `?page=${page}&query=${query}`}&active=${active}`)
    return response
}

const getById = async (id: number) => {
    await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: ApiConstants.baseUrl })
    let response = await axiosInstance.get(`/products/${id}`)
    return response
}

const store = async (data: IPostProductRequest) => {
    let response = await axiosInstance.post('/products', data)
    return response
}

const update = async (data: IPostProductRequest, id: number) => {
    let response = await axiosInstance.put(`/products/${id}`, data)
    return response
}

const updateStatus = async (data: IPutProductStatus, id: number) => {
    let response = await axiosInstance.put(`/products/${id}/active`, data)
    return response
}

const uploadImages = async (data: FormData, id: number) => {
    let response = await axiosInstance.post(`/products/${id}/images`, data, { headers: { 'content-type': 'multipart/form-data' } })
    return response
}
const removeImgs = async (id: number) => {
    let response = await axiosInstance.delete(`/products/${id}/images`)
    return response
}



const productRepository = {
    getAll,
    getById,
    store,
    update,
    updateStatus,
    uploadImages,
    removeImgs,
}

export default productRepository