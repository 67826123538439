import React, { useEffect, useState } from 'react';
import {
    TextField, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, Avatar, Box,
    Container,
    Stack,
    Grid,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit'; // Ícone de edição
import SaveIcon from '@mui/icons-material/Save'; // Ícone de salvar
import CancelIcon from '@mui/icons-material/Cancel'; // Ícone de cancelar
import { ICompanyPutRequest } from '../../shared/services/models/company';
import { useAuth } from '../../shared/hooks/useAuth';
import bairrosRepository from '../../shared/services/repositories/bairros_repository';
import { IBairroResponse } from '../../shared/services/models/bairro';
import { CheckBox } from '@mui/icons-material';
import authRepository from '../../shared/services/repositories/auth_repository';
import { IAuthModel, IUserModel } from '../../shared/services/models/auth';
import { LoadingButton } from '@mui/lab';
import ImageUpload from '../../components/img_upload';
import { ApiConstants } from '../../constants/api_constants';




const PerfilPage = () => {

    const { auth, setAuth } = useAuth()

    const [userData, setUserData] = useState<ICompanyPutRequest>({
        name: auth?.user?.company?.name || '',
        email: auth?.user?.company?.email || '',
        address: auth?.user?.company?.address || '',
        latitude: auth?.user?.company?.latitude || '',
        longitude: auth?.user?.company?.longitude || '',
        neighborhood_id: auth?.user?.company?.neighborhood_id ?? 0,
        observations: auth?.user?.company?.observations || '',
        nuit: auth?.user?.company?.nuit || '',
        phone_number1: auth?.user?.company?.phone_number1 || '',
        phone_number2: auth?.user?.company?.phone_number2 || '',
        password: '',
        old_password: '',
        password_confirmation: ''
    });

    const [formErrors, setFormErrors] = useState<ICompanyPutRequest>({
    });

    const [formLoading, setFormLoading] = useState(false)

    const [bairros, setBairros] = useState<IBairroResponse[]>([])
    const [loadingBairros, setLoadingBairros] = useState(false)

    const [refresh, setRefresh] = useState(false)
    const [checked, setChecked] = useState(false); // Estado inicial

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        setLoadingBairros(true)
        bairrosRepository
            .getAll()
            .then(res => {
                setBairros(res.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoadingBairros(false)
            })
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (auth) {

                const userData = {
                    name: auth.user?.company?.name || '',
                    email: auth.user?.company?.email || '',
                    address: auth.user?.company?.address || '',
                    latitude: auth.user?.company?.latitude || '',
                    longitude: auth.user?.company?.longitude || '',
                    neighborhood_id: auth.user?.company?.neighborhood_id ?? 0,
                    observations: auth.user?.company?.observations || '',
                    nuit: auth.user?.company?.nuit || '',
                    phone_number1: auth.user?.company?.phone_number1 || '',
                    phone_number2: auth.user?.company?.phone_number2 || '',
                    password: '',
                    old_password: '',
                    password_confirmation: ''
                    // inicialize outros campos conforme necessário
                };

                setUserData(userData);
            }
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [refresh]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setOpenPasswordDialog(true);
    };

    const handleConfirmPassword = () => {
        setFormErrors({});

        if ((userData.old_password?.length ?? 0) > 7) {
            setOpenPasswordDialog(false);
            handleSubmitForm();
        } else {
            setFormErrors(old => ({ ...old, old_password: 'senha invalida' }))
        }
    };

    const handleCancelEdit = () => {
        setRefresh(old => !old)
        setChecked(false)
        setIsEditing(false);
        setFormErrors({})
    };

    const handleSubmitForm = () => {
        setFormLoading(true)
        console.log("enviado", userData)
        authRepository
            .updateCompany(auth?.user?.company_id ?? 0, userData)
            .then(response => {
                setFormErrors({})
                const newAuth: IAuthModel = { ...auth, user: response.data }
                setAuth(newAuth);
                setIsEditing(false)
                alert('Informações actuaizadas com sucesso')
            })
            .catch(error => {

                if (error?.data) {
                    setFormErrors(error?.data.errors)
                    alert(error?.data?.message ?? '')
                    console.log('error', error.data)
                } else {
                    alert(error?.message)
                }

            }).finally(() => {
                setFormLoading(false)
            })
    }

    return (
        <Container sx={{ my: 2, maxWidth: '100vw', position: 'relative' }} >
            <Stack direction={'row'} alignContent={'baseline'} justifyContent={'space-between'} mb={4}>
                <ImageUpload companyId={auth?.user?.company_id ?? 0} initialImageUrl={ApiConstants.baseUrlFiles + (auth?.user?.company?.logo_url ?? '')} />

                {/* Botões de ação usando ícones */}
                {!isEditing && (
                    <Box>
                        <IconButton color="warning" onClick={handleEditClick} sx={{ border: 1, borderRadius: 2, mr: 3 }}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                )}
            </Stack>

            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                <TextField
                    label="Username"
                    name="username"
                    value={auth?.user?.username}
                    // onChange={handleInputChange}
                    disabled={true}
                    fullWidth
                />
                <TextField
                    label="Nome"
                    name="name"
                    value={userData.name}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    fullWidth
                    error={formErrors?.name !== undefined}
                    helperText={formErrors?.name}
                />
                <TextField
                    label="Email"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    fullWidth
                    error={formErrors?.email !== undefined}
                    helperText={formErrors?.email}
                />
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <TextField
                            label="Telefone 1"
                            name="phone_number1"
                            value={userData.phone_number1}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            fullWidth
                            error={formErrors?.phone_number1 !== undefined}
                            helperText={formErrors?.phone_number1}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            label="Telefone 2"
                            name="phone_number2"
                            value={userData.phone_number2}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            fullWidth
                            error={formErrors?.phone_number2 !== undefined}
                            helperText={formErrors?.phone_number2}
                        />

                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            label="Nuit"
                            name="nuit"
                            value={userData.nuit}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            fullWidth
                            error={formErrors?.nuit !== undefined}
                            helperText={formErrors?.nuit}
                        />

                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item md={3}>
                        {
                            loadingBairros ? <TextField fullWidth
                                label="Bairro" /> :
                                <TextField select
                                    label="Bairro"
                                    name="neighborhood_id"
                                    value={userData.neighborhood_id}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    fullWidth
                                    error={formErrors?.neighborhood_id !== undefined}
                                    helperText={formErrors?.neighborhood_id}
                                >
                                    <MenuItem key={0} value={0}></MenuItem>
                                    {
                                        bairros.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Endereço"
                            name="address"
                            value={userData.address}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            fullWidth
                            error={formErrors?.address !== undefined}
                            helperText={formErrors?.address}
                        />

                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Longitude"
                            name="longitude"
                            value={userData.longitude}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            fullWidth
                            error={formErrors?.longitude !== undefined}
                            helperText={formErrors?.longitude}
                        />

                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Latitude"
                            name="latitude"
                            value={userData.latitude}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            fullWidth
                            error={formErrors?.latitude !== undefined}
                            helperText={formErrors?.latitude}
                        />

                    </Grid>
                </Grid>

                <TextField multiline
                    minRows={5}
                    label="Informação adicional"
                    name="observations"
                    value={userData.observations}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    fullWidth
                    error={formErrors?.observations !== undefined}
                    helperText={formErrors?.observations}
                />

                <Box component="fieldset">
                    <legend> <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!isEditing}
                                checked={checked} // Estado de seleção do checkbox
                                onChange={(event) => {

                                    var value = event.target.checked;
                                    if (!value) {
                                        setUserData(prev => ({ ...prev, password: '', password_confirmation: '' }))
                                    }
                                    setChecked(value)

                                }
                                } // Função para capturar a alteração
                                inputProps={{ 'aria-label': 'checkbox' }}
                            />
                        }
                        label="Redifinir a senha"
                    /> </legend>
                    <Stack spacing={2}>

                        <TextField
                            label="Nova senha"
                            name="password"
                            type='password'
                            value={userData.password}
                            onChange={handleInputChange}
                            disabled={!isEditing || !checked}
                            fullWidth
                            error={formErrors?.password !== undefined}
                            helperText={formErrors?.password}
                        />
                        <TextField
                            label="Confirmar a nova senha"
                            name="password_confirmation"
                            type='password'
                            value={userData.password_confirmation}
                            onChange={handleInputChange}
                            disabled={!isEditing || !checked}
                            fullWidth
                            error={formErrors?.password_confirmation !== undefined}
                            helperText={formErrors?.password_confirmation}
                        />
                    </Stack>
                </Box>

                {isEditing && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button variant='contained' color="secondary" onClick={handleCancelEdit}>
                            <CancelIcon />
                            Cancelar
                        </Button>
                        <LoadingButton
                            loading={formLoading}
                            variant='contained' color="success" onClick={handleSaveClick}>
                            <SaveIcon />
                            Guardar
                        </LoadingButton>
                    </Box>
                )}
            </Box>

        
            <Dialog fullWidth disableEscapeKeyDown={true} open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
                <DialogTitle>Confirme com a Senha</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Senha"
                        name='old_password'
                        variant='filled'
                        type="password"
                        fullWidth
                        value={userData.old_password}
                        onChange={handleInputChange}
                        error={formErrors?.old_password !== undefined}
                        helperText={formErrors?.old_password}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPasswordDialog(false)} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmPassword} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default PerfilPage