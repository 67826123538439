import { Autocomplete, Divider, Grid, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { IPostProductErrorsRequest, IPostProductRequest } from '../../shared/services/models/product'
import { ProductType } from '../../constants/api_constants'


interface IGeralInfoProps extends IPostProductRequest {
    setFormValues: React.Dispatch<React.SetStateAction<IPostProductRequest>>
    errors?: IPostProductErrorsRequest,
    isEditpage?: boolean,
}

const GeralInfoTab: React.FC<IGeralInfoProps> = (props) => {
    const ivaOptions = [
        { label: '0', value: 0 },
        { label: '5', value: 5 },
        { label: '17', value: 17 },
    ];
    return (
        <Stack spacing={2} direction={'column'}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                    <TextField
                        required
                        label='Descrição'
                        variant='filled'
                        fullWidth
                        value={props.description}
                        onChange={e => props.setFormValues(old => ({ ...old, description: e.target.value }))}
                        helperText={props?.errors?.description}
                        error={typeof props?.errors?.description !== 'undefined'}
                    />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <TextField
                        label='Código (SKU)'
                        variant='filled'
                        fullWidth
                        value={props.sku}
                        onChange={e => props.setFormValues(old => ({ ...old, sku: e.target.value }))}
                        helperText={props?.errors?.sku || 'Se não especificado será gerado automaticamente'}
                        error={typeof props?.errors?.sku !== 'undefined'}
                    />
                </Grid>
                {/* <Grid item xs={12} lg={4}>
                    <TextField select
                        fullWidth
                        label='Tipo de produto'
                        placeholder='Tipo'
                        variant='filled'
                        value={props.type}
                        onChange={e => props.setFormValues(old => ({ ...old, type: e.target.value }))}
                        helperText={props?.errors?.type || 'Referência(possui variações), Produto(não possui)'}
                        error={typeof props?.errors?.type !== 'undefined'}
                    >
                        {
                            ProductType.getArray().map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                )
                            })
                        }

                    </TextField>
                </Grid> */}
                <Grid item xs={12} lg={4}>
                    <TextField
                        fullWidth
                        label='Código de barras'
                        variant='filled'
                        value={props.bar_code}
                        onChange={e => props.setFormValues(old => ({ ...old, bar_code: e.target.value }))}
                        helperText={props?.errors?.bar_code || 'Deve ser único'}
                        error={typeof props?.errors?.bar_code !== 'undefined'}
                    />

                </Grid>
                {/* <Grid item lg={4}></Grid> */}
                <Grid item xs={12} lg={3}>
                    <TextField
                        required
                        fullWidth
                        type='number'
                        label='Preço de venda'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>MZN</InputAdornment>
                        }}
                        value={props.price}
                        onChange={e => props.setFormValues(old => ({ ...old, price: Number(e.target.value) }))}
                        helperText={props?.errors?.price}
                        error={typeof props?.errors?.price !== 'undefined'}
                    />
                </Grid>
                {/* <Grid item xs={12} lg={3}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Preço promocional'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>MZN</InputAdornment>,
                        }}
                        value={props.promotional_price}
                        onChange={e => props.setFormValues(old => ({ ...old, promotional_price: Number(e.target.value) }))}
                        helperText={props?.errors?.promotional_price}
                        error={typeof props?.errors?.promotional_price !== 'undefined'}
                    />
                </Grid> */}
                <Grid item xs={12} lg={3}>
                    <TextField
                        required
                        fullWidth
                        label='Unidade'
                        variant='filled'
                        value={props.unity}
                        onChange={e => props.setFormValues(old => ({ ...old, unity: e.target.value }))}
                        helperText={props?.errors?.unity || 'ex. pacote, un, kg, litros, ...'}
                        error={typeof props?.errors?.unity !== 'undefined'}
                    />
                </Grid>
            </Grid >
            <Divider />
            <Typography variant='h6' >
                Dimenssões e Pesos
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Peso liquido'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>kg</InputAdornment>
                        }}
                        value={props.net_weight}
                        onChange={e => props.setFormValues(old => ({ ...old, net_weight: Number(e.target.value) }))}
                        helperText={props?.errors?.net_weight}
                        error={typeof props?.errors?.net_weight !== 'undefined'}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Peso bruto'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>kg</InputAdornment>
                        }}
                        value={props.gross_weight}
                        onChange={e => props.setFormValues(old => ({ ...old, gross_weight: Number(e.target.value) }))}
                        helperText={props?.errors?.gross_weight}
                        error={typeof props?.errors?.gross_weight !== 'undefined'}
                    />
                </Grid>
                {/* <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='N° de volumes'
                        variant='filled'
                        helperText={props?.errors?.unity || 'ex. pacote, un, kg, litros, ...'}
                        error={typeof props?.errors?.unity !== 'undefined'}
                    />
                </Grid> */}
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Altura'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>cm</InputAdornment>
                        }}
                        value={props.height}
                        onChange={e => props.setFormValues(old => ({ ...old, height: Number(e.target.value) }))}
                        helperText={props?.errors?.height}
                        error={typeof props?.errors?.height !== 'undefined'}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Largura'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>cm</InputAdornment>
                        }}
                        value={props.width}
                        onChange={e => props.setFormValues(old => ({ ...old, width: Number(e.target.value) }))}
                        helperText={props?.errors?.width}
                        error={typeof props?.errors?.width !== 'undefined'}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Comprimento'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>cm</InputAdornment>
                        }}
                        value={props.length}
                        onChange={e => props.setFormValues(old => ({ ...old, length: Number(e.target.value) }))}
                        helperText={props?.errors?.length}
                        error={typeof props?.errors?.unity !== 'undefined'}
                    />
                </Grid>
            </Grid>
            <Divider />
            <Typography variant='h6' >
                Estoque
            </Typography>
            <Grid container spacing={2}>
                {(props.isEditpage !=true &&
                    <Grid item xs={12} lg={2}>
                        <TextField
                            fullWidth
                            type='number'
                            label='Estoque inicial'
                            variant='filled'
                            InputProps={{
                                endAdornment: <InputAdornment position='start'>{props.unity}</InputAdornment>
                            }}
                            value={props.qty}
                            onChange={e => props.setFormValues(old => ({ ...old, qty: Number(e.target.value) }))}
                            helperText={props?.errors?.qty || 'Se deseja lançar o estoque inicial'}
                            error={typeof props?.errors?.qty !== 'undefined'}
                        />
                    </Grid>
                )}
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Estoque mínimo'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>{props.unity}</InputAdornment>
                        }}
                        value={props.minimum_stock}
                        onChange={e => props.setFormValues(old => ({ ...old, minimum_stock: Number(e.target.value) }))}
                        helperText={props?.errors?.minimum_stock || 'Quantidade mínima a ser comprada'}
                        error={typeof props?.errors?.minimum_stock !== 'undefined'}
                    />
                </Grid>
                {/* <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        type='number'
                        label='Estoque máximo'
                        variant='filled'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'>{props.unity}</InputAdornment>
                        }}
                        value={props.maximum_stock}
                        onChange={e => props.setFormValues(old => ({ ...old, maximum_stock: Number(e.target.value) }))}
                        helperText={props?.errors?.maximum_stock || 'Quantidade máxima do produto no estoque'}
                        error={typeof props?.errors?.maximum_stock !== 'undefined'}
                    />
                </Grid> */}
                {/* <Grid item xs={12} lg={2}>
                    <TextField select
                        fullWidth
                        type='number'
                        label='Controlar a validade'
                        variant='filled'
                        value={props.check_validity}
                        onChange={e => props.setFormValues(old => ({ ...old, check_validity: Number(e.target.value) }))}
                        helperText={'Verificar a validade do produto'}
                    >
                        <MenuItem value={1}>Sim</MenuItem>
                        <MenuItem value={0}>Não</MenuItem>
                    </TextField>
                </Grid> */}
                {/* <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        label='Localização'
                        variant='filled'
                        helperText={props?.errors?.localization || 'Localização física no estoque ex. corredor A'}
                        error={typeof props?.errors?.localization !== 'undefined'}
                    />
                </Grid> */}
            </Grid>

            <Divider />
            <Typography variant='h6' >
                Dados fiscais
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <Autocomplete

                        options={ivaOptions.map(option => option.label)} // Mapeia apenas os rótulos
                        freeSolo // Permite inserir valores personalizados
                        value={props.IVA?.toString()}
                        onChange={(e, value) => props.setFormValues(old => ({ ...old, IVA: Number(value) }))}
                        renderInput={(params) => (
                            <TextField {...params} label="Taxa de IVA (%)" margin="normal" variant="filled" type='number' />
                        )}
                    />
                </Grid>
            </Grid>
        </Stack>
    )
}

export default GeralInfoTab