import React from 'react'
import ShowCode from '../../components/show_code'
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../shared/hooks/useAuth';


function IntegrationPage() {

	const { auth } = useAuth()
	//integracoes
	const headersExample = `
          HOST: https://api.gueva.site/api
          POST /login/company  
          ApplicationId: seu_application_id
          SpecialCode: seu_special_code
  `

	//productos
	const getProductsResponse = ` 
  {
	"current_page": 1,
	"data": [
		{
			"id": 3,
			"sku": "sdsdssd",
			"bar_code": "fdsfsss",
			"brand": "Maevas",
			"description": "Acucar castanho verde de alguns que",
			"attributes": null,
			"qty": 34,
			"qty_reserved": 0,
			"qty_available": 34,
			"minimum_stock": 2,
			"maximum_stock": 2,
			"gross_weight": 11,
			"net_weight": 0,
			"height": 10.5,
			"width": 0,
			"length": null,
			"imgs_url": null,
			"check_validity": true,
			"active": true,
			"price": 456.54,
			"IVA": 0,
			"promotional_price": null,
			"unity": "Un",
			"complemtary_description": null,
			"localization": null,
			"reference": null,
			"category_id": null,
			"company_id": 8,
			"created_at": "2024-07-04T15:18:31.000000Z",
			"updated_at": "2024-07-04T15:18:31.000000Z"
		}
	],
	"first_page_url": "http:\/\/localhost:8010\/api\/products?page=1",
	"from": 1,
	"last_page": 1,
	"last_page_url": "http:\/\/localhost:8010\/api\/products?page=1",
	"links": [
		{
			"url": null,
			"label": "&laquo; Anterior",
			"active": false
		},
		{
			"url": "http:\/\/localhost:8010\/api\/products?page=1",
			"label": "1",
			"active": true
		},
		{
			"url": null,
			"label": "Próximo &raquo;",
			"active": false
		}
	],
	"next_page_url": null,
	"path": "http:\/\/localhost:8010\/api\/products",
	"per_page": 5,
	"prev_page_url": null,
	"to": 2,
	"total": 2
}
  `
	const postProductBody = `
  {
	"active": true,
	"bar_code": "xxxxxx",
	"brand": "Mariana",
	"check_validity": true,
	"description": "Arroz",
	"gross_weight": 11.15,
	"height": 10.5,
	"qty": 34,
	"lenght": 0,
	"maximum_stock": 2,
	"minimum_stock": 2,
	"net_weight": 0,
	"price": 456.54,
	"sku": "xxxxxxx",
	"width": 0,
	"unity": "Un"
}
  `
	const postProductResponse = `
  {
	"active": true,
	"bar_code": "xxxxxx",
	"brand": "Mariana",
	"check_validity": true,
	"description": "Arroz",
	"gross_weight": 11,
	"height": 10.5,
	"qty": 34,
	"maximum_stock": 2,
	"minimum_stock": 2,
	"net_weight": 0,
	"price": 456.54,
	"sku": "xxxxxxx",
	"width": 0,
	"unity": "Un",
	"category_id": null,
	"qty_available": 34,
	"company_id": 8,
	"updated_at": "2024-07-06T22:14:36.000000Z",
	"created_at": "2024-07-06T22:14:36.000000Z",
	"id": 11
}
  `
	//sales
	const getSalesResponse = `
  {
	"current_page": 1,
	"data": [
		{
			"id": 1,
			"origin": "LOCAL",
			"m_code": null,
			"code": "081024201-670569f39c991",
			"total_price": 1686,
			"client_code": "INTERNO",
			"user": {
				"id": 1,
				"name": "Teste1",
				"email": "teste@gmail.com",
				"username": "admin@teste1",
				"last_name": null,
				"phoneNumber": null,
				"type": 500,
				"city": null,
				"address": null,
				"permissions": "*",
				"active": true,
				"company_id": 1,
				"email_verified_at": null,
				"created_at": "2024-10-08T17:17:06.000000Z",
				"updated_at": "2024-10-08T17:17:06.000000Z"
			},
			"closed": null,
			"refunded": null,
			"status": "PAGO",
			"integrated": true,
			"py_id": 1,
			"company_id": 1,
			"user_id": 1,
			"created_at": "2024-10-08T17:20:51.000000Z",
			"updated_at": "2024-10-08T17:48:40.000000Z",
			"payment_method": {
				"id": 1,
				"name": "M-PESA",
				"active": true,
				"created_at": null,
				"updated_at": null
			},
			"sale_products": [
				{
					"id": 1,
					"code": "081024201-670569f39c991",
					"price": 562,
					"qty": 3,
					"product_id": 1,
					"created_at": "2024-10-08T17:20:51.000000Z",
					"updated_at": "2024-10-08T17:20:51.000000Z"
				}
			]
		}
	],
	"first_page_url": "http:\/\/localhost:8010\/api\/sales\/integration?page=1",
	"from": 1,
	"last_page": 1,
	"last_page_url": "http:\/\/localhost:8010\/api\/sales\/integration?page=1",
	"links": [
		{
			"url": null,
			"label": "&laquo; Anterior",
			"active": false
		},
		{
			"url": "http:\/\/localhost:8010\/api\/sales\/integration?page=1",
			"label": "1",
			"active": true
		},
		{
			"url": null,
			"label": "Próximo &raquo;",
			"active": false
		}
	],
	"next_page_url": null,
	"path": "http:\/\/localhost:8010\/api\/sales\/integration",
	"per_page": 10,
	"prev_page_url": null,
	"to": 1,
	"total": 1
}
  `
	const patchIntegratedSaleResponse = `
  {
	 "integrated": true
  }
  `
	return (
		<Container sx={{ my: 4 }}>
			<Stack spacing={2}>

				<Box>
					<Typography variant="h5" gutterBottom>
						Configurando Sua Aplicação para Integração com Nosso Marketplace
					</Typography>
					<Typography variant="body1" paragraph>
						Para integrar sua aplicação com nosso marketplace, você precisará configurar suas requisições HTTP para incluir o token obtido através de dois cabeçalhos especiais: <strong>ApplicationId</strong> e <strong>SpecialCode</strong>. Esses cabeçalhos são necessários para autenticar e identificar sua aplicação ao interagir com nossos serviços.
					</Typography>

					<Typography variant="h6" gutterBottom>
						Passos para Configuração:
					</Typography>
					<ol>
						<li>
							<Typography variant="body1" paragraph>
								<strong>Copie as suas Credenciais de Integração:</strong> O <strong>ApplicationId</strong> e <strong>SpecialCode</strong>. Esses códigos são únicos para cada aplicação e garantem segurança e rastreabilidade nas interações com nosso marketplace.
							</Typography>
							<Typography >
								ApplicationId
							</Typography>
							<ShowCode code={auth?.user?.username ?? ''} language='' showLineNumbers={false} />
							<Typography>
								SpecialCode
							</Typography>
							<ShowCode code={auth?.user?.company?.code ?? ''} language='' showLineNumbers={false} />

						</li>
						<li>
							<Typography variant="body1" paragraph>
								<strong>Obtenha o token:</strong> Adicione o token nas requisições posteriores:
								<ul>
									<li><strong>ApplicationId:</strong> Representa o identificador da empresa.</li>
									<li><strong>SpecialCode:</strong> Inclua o código especial também fornecido por nós, para garantir que as requisições sejam autenticadas corretamente.</li>
								</ul>
							</Typography>
						</li>
					</ol>

					<ShowCode code={headersExample} language='' showLineNumbers={false} />

				</Box>



				<Box>
					<Typography variant='h5' fontWeight={600}>
						Lista dos produtos
					</Typography>
					<ShowCode code='GET /products' language='' showLineNumbers={false} />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Resposta da lista dos produtos</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={getProductsResponse} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
				</Box>


				

				<Box>
					<Typography variant='h5' fontWeight={600}>
						Criar produto
					</Typography>
					<ShowCode code='POST /products' language='' showLineNumbers={false} />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Corpo da requisição</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={postProductBody} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Resposta da criação do produto</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={postProductResponse} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
				</Box>



				<Box>
					<Typography variant='h5' fontWeight={600}>
						Actualizar produto
					</Typography>
					<ShowCode code='PATCH /products/codigo' language='' showLineNumbers={false} />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Corpo da requisição</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={postProductBody} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Resposta da actualização do produto</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={postProductResponse} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
				</Box>


		
				<Box>
					<Typography variant='h5' fontWeight={600}>
						Lista das vendas
					</Typography>
					<ShowCode code='GET /sales' language='' showLineNumbers={false} />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Resposta da lista das vendas</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={getSalesResponse} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
				</Box>


				<Box>
					<Typography variant='h5' fontWeight={600}>
						Marcar uma venda como integrada
					</Typography>
					<ShowCode code='PATCH /sale/integraction/{code}' language='' showLineNumbers={false} />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Corpo da requisição</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={patchIntegratedSaleResponse} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
				</Box>


				<Box>
					<Typography variant='h5' fontWeight={600}>
						Lista das vendas não integradas
					</Typography>
					<ShowCode code='GET /sales/integration?integrated=0' language='' showLineNumbers={false} />
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Resposta da lista das vendas não integradas</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ShowCode code={getSalesResponse} language='json' showLineNumbers={false} />
						</AccordionDetails>
					</Accordion>
				</Box>

			</Stack>
		</ Container>
	)
}

export default IntegrationPage