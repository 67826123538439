import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Button, Stack } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadIcon from '@mui/icons-material/CloudUpload'; // Ícone de upload
import authRepository from '../../shared/services/repositories/auth_repository';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../shared/hooks/useAuth';

interface ImageUploadProps {
    initialImageUrl?: string;
    companyId: number // Propriedade opcional para receber uma URL inicial
}

const ImageUpload: React.FC<ImageUploadProps> = ({ initialImageUrl, companyId }) => {
    const [image, setImage] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null); // Estado para o arquivo

    const { auth, setAuth } = useAuth();

    const [uploading, setUploading] = useState(false)
    // Define a imagem inicial vinda da internet
    useEffect(() => {
        if (initialImageUrl) {
            setImage(initialImageUrl);
        }
    }, [initialImageUrl]);

    // Referência ao input de arquivo para acioná-lo programaticamente
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result as string);
                setFile(selectedFile); // Define o arquivo selecionado
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setFile(null); // Limpa o arquivo ao remover a imagem
    };

    const handleAddOrUpdateClick = () => {
        // Aciona o clique no input de arquivo ao adicionar ou atualizar
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleUpload = () => {
        if (!file) {
            removeImage()
            return;
        }

        // Lógica para enviar o arquivo para a API
        const formData = new FormData();
        formData.append('logo_url', file); // Adiciona o arquivo diretamente

        setUploading(true)

        authRepository
            .uploadImage(companyId, formData)
            .then(response => {
                alert("Logotipo actualizado com sucesso!");

                var newAuth = auth
                if (newAuth?.user?.company != undefined) {
                    newAuth.user.company.logo_url = response.data.logo_url;
                    setAuth(newAuth)
                }

            })
            .catch(error => {
                if (error?.data) {
                    alert(error.data?.message)
                    console.log(error)
                } else {
                    alert(error?.message)
                }
            }).finally(() => {
                setUploading(false)
            })
    };

    const removeImage = () => {
        setUploading(true)
        authRepository
            .deleteImage(companyId)
            .then(response => {
                alert("Logotipo actualizado com sucesso!");

                var newAuth = auth
                if (newAuth?.user?.company != undefined) {
                    newAuth.user.company.logo_url = '';
                    setAuth(newAuth)
                }
            })
            .catch(error => {
                if (error?.data) {
                    alert(error.data?.message)
                    console.log(error)
                } else {
                    alert(error?.message)
                }
            }).finally(() => {
                setUploading(false)
            })
    }

    return (
        <Stack spacing={2}>
            <Box
                sx={{
                    width: 200,
                    height: 200, // Aumente a altura para acomodar o botão
                    border: '2px dashed #ccc',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'pointer',
                }}
            >
                {image ? (
                    <>
                        <img
                            src={image}
                            alt="Uploaded"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: 'rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            <IconButton
                                aria-label="update"
                                sx={{ color: 'white', marginRight: '8px' }}
                                onClick={handleAddOrUpdateClick}
                            >
                                <RefreshIcon fontSize="large" />
                            </IconButton>
                            <IconButton
                                aria-label="delete"
                                sx={{ color: 'white' }}
                                onClick={handleRemoveImage}
                            >
                                <DeleteIcon fontSize="large" />
                            </IconButton>
                        </Box>
                    </>
                ) : (
                    <>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={handleAddOrUpdateClick}
                        >
                            <AddPhotoAlternateIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="body1" color="textSecondary">
                            Clique para adicionar
                        </Typography>
                    </>
                )}

                {/* Input de arquivo escondido */}
                <input
                    id="imageInput"
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef} // Referência ao input
                />

            </Box>

            {/* Botão de upload */}
            <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<UploadIcon />}
                sx={{ marginTop: 2 }} // Espaço acima do botão
                onClick={handleUpload}
                loading={uploading}
            >
                Guardar
            </LoadingButton>
        </Stack>
    );
};

export default ImageUpload;
