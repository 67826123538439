export class ApiConstants {

    // static readonly baseUrl = 'http://localhost:8010'
    static readonly baseUrl = 'https://api.gueva.site'
    static readonly baseUrlApi = `${this.baseUrl}/api`
    static readonly baseUrlFiles = `${this.baseUrl}/storage`
}

export class ProductType {

    static readonly product = 'PRODUCT'
    static readonly reference = 'REFERENCE'

    static getArray() {
        return [this.product, this.reference]
    }

}

export class SaleStatus {
    static readonly TODOS = 'TODOS'
    static readonly PAGO = 'PAGO'
    static readonly DEVOLVIDO = 'DEVOLVIDO'
    static readonly FECHADO = 'FECHADO'


    static toArray() {
        return [this.TODOS, this.PAGO, this.DEVOLVIDO, this.FECHADO]
    }

}