import { stat } from "fs"
import axiosInstance from "../../api/api_client"
import { IPostProductRequest } from "../models/product"
import { IPostSaleRequest, IPutSaleStatusRequisition } from "../models/sale"
import { ApiConstants } from "../../../constants/api_constants"
import { ILoginPost, IUserRegisterPost } from "../models/auth"
import { ICompanyPostRequest, ICompanyPutRequest } from "../models/company"



const csrf = async () => {
    await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: ApiConstants.baseUrl })
}
const login = async (data: ILoginPost) => {
    let response = await axiosInstance.post(`/login`, data)
    return response
}

const logout = async () => {
    let response = await axiosInstance.get(`/logout`)
    return response
}
const getUser = async () => {
    await csrf()
    let response = await axiosInstance.get(`/auth/user`)
    return response
}

const register = async (data: ICompanyPostRequest) => {
    let response = await axiosInstance.post('/companies', data)
    return response
}

const updateCompany = async (id: number, data: ICompanyPutRequest) => {
    let response = await axiosInstance.put(`/companies/${id}`, data)
    return response
}
const uploadImage = async (id: number, data: FormData) => {
    let response = await axiosInstance.post(`/companies/${id}/image`, data, { headers: { 'content-type': 'multipart/form-data' } })
    return response
}
const deleteImage = async (id: number) => {
    let response = await axiosInstance.delete(`/companies/${id}/image`)
    return response
}



const authRepository = {
    login,
    register,
    logout,
    getUser,
    updateCompany,
    deleteImage,
    uploadImage
}

export default authRepository