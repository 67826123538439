
import AddIcon from '@mui/icons-material/Add'
import FilterListIcon from '@mui/icons-material/FilterList'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import SearchIcon from '@mui/icons-material/Search'
import LockIcon from '@mui/icons-material/Lock';
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Container, Grid, IconButton, LinearProgress, InputAdornment, Pagination, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RouteHelper } from '../../helpers/route_helper'
import { IGetProdutResponse } from '../../shared/services/models/product'
import { IResponsePaginated } from '../../shared/services/models/response'
import productRepository from '../../shared/services/repositories/product_repository'
import Image from 'mui-image'
import { ApiConstants } from '../../constants/api_constants'

const ProductsPage = () => {

  const navigate = useNavigate()
  const [products, setProducts] = useState<IResponsePaginated<IGetProdutResponse[]>>({})
  const [page, setPage] = useState(1)
  const [pageLoading, setPageLoading] = useState(true)
  const [productsLoading, setproductLoading] = useState(true)

  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    (
      async () => {
        await getProducts({ first: true })
        setPageLoading(false)
      }
    )();

  }, [])

  async function getProducts(data: { page?: number, first?: boolean }) {

    setproductLoading(true)

    await productRepository
      .getAll(data.page || 1, undefined, search)
      .then(response => {
        console.log(response.data)
        setProducts(response.data)
      })
      .catch(error => {
        if (error?.data) {
          alert("Erro ao buscar os productos, tente novamente!")
          console.log(error?.data?.message)
        }
        alert(error.code)
      })
      .finally(() => {
        setproductLoading(false)
      })
  }

  async function handleChangePage(p: number) {
    setPage(p)
    await getProducts({ page: p })
  }

  function changeStatus(id: number) {
    let newProducts = products?.data?.map(product => {
      if (product.id === id)
        product.active = !product.active;
      return product;
    })
    setProducts(old => ({ ...old, data: newProducts }))
  }


  return (
    <Container sx={{
      my: 2,
      maxWidth: '100vw',
      position: 'relative'
    }} >
      <Stack spacing={2}>
        <Typography variant='h5'>
          Produtos
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            size='small'
            sx={{ borderRadius: 6, mx: 2 }}
            onClick={() => navigate(RouteHelper.addProducts)}
          >
            Adicionar
          </Button>
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextField
              placeholder='Pesquise por nome, codigo de barras ou sku'
              size='small'
              value={search}
              onChange={e => setSearch(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='start' >
                  <IconButton onClick={e => getProducts({ first: true })}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }}
            />
          </Grid>
          {/* <Grid item xs={12} lg={6}>

            <Button
              startIcon={<FilterListIcon />}
              variant='outlined' size='small'
              sx={{ borderRadius: 6, mx: 2 }}
            >
              filtros
            </Button>
            <Button
              startIcon={<RemoveCircleIcon />}
              variant='outlined' size='small'
              sx={{ borderRadius: 6 }}
            >
              limpar filtros
            </Button>

          </Grid> */}
        </Grid>
        <Paper sx={{ overflow: 'visible', maxWidth: '100%' }}>
          {
            pageLoading || productsLoading ?
              <LinearProgress color='success' />
              : <></>
          }
          <ProductTable products={products?.data || []} changeStatus={changeStatus} />
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
          >
            <Pagination
              count={products?.last_page}
              page={page}
              onChange={(e, p) => handleChangePage(p)}
              sx={{ my: 2 }}
            />
          </Stack>
        </Paper>
      </Stack>
    </Container>
  )
}

interface IProductTableProps {
  products: IGetProdutResponse[],
  changeStatus: (id: number) => void,
}

const ProductTable: React.FC<IProductTableProps> = (props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  function handleUpdateActive(id: number, active: boolean) {
    setLoading(true)
    productRepository
      .updateStatus({ active: active }, id)
      .then(res => {
        console.log(res.data)
        props.changeStatus(id)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Descrição</TableCell>
          <TableCell>Código (SKU)</TableCell>
          <TableCell>Preço</TableCell>
          <TableCell>Estoque físico</TableCell>
          <TableCell>Unidade</TableCell>
          <TableCell>Integrações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.products.map((product, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <Image src={ApiConstants.baseUrlFiles + (JSON.parse(product.imgs_url || '[]')[0] || '/0')} width={'5rem'} showLoading />
                </TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.sku}</TableCell>
                <TableCell>{product.price?.toFixed(2)}</TableCell>
                <TableCell>{product.qty}</TableCell>
                <TableCell>{product.unity}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleUpdateActive(product.id as number, !(product.active as boolean))}
                  >
                    {
                      product.active ?
                        <LockOpenSharpIcon color='success' /> :
                        <LockIcon color='error' />
                    }
                  </IconButton>
                  <IconButton
                    onClick={() => navigate(RouteHelper.updateProduct(product.id || 0))}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>

  )
}


export default ProductsPage