import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DarckModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, IconButton, Toolbar, Typography, useTheme } from '@mui/material'
import React from 'react'
import useColorMode from '../../shared/hooks/useColorMode'
import { useNavigate } from 'react-router-dom'
import { AccountCircle, Logout } from '@mui/icons-material'
import authRepository from '../../shared/services/repositories/auth_repository'
import { AppConst } from '../../constants/app_constants'
import { useAuth } from '../../shared/hooks/useAuth'
import { RouteHelper } from '../../helpers/route_helper'

interface IAppBarTopProps {
    drawerProps: {
        open: boolean,
        width: number,
        handleDrawerToggle: () => void
    }
}

const AppBarTop: React.FC<IAppBarTopProps> = (props) => {

    const theme = useTheme()
    const { toggleColorMode } = useColorMode()
    const navigate = useNavigate()
    const { setAuth } = useAuth()

    function logOut() {
        try {
            setAuth({})
            //guarda o token
            localStorage.removeItem(AppConst.name)
            navigate(RouteHelper.login)
        } catch (ex) {
            alert("Erro ao fazer o lagout, tente novamente!")
        }
    }


    return (
        <AppBar
            position="fixed"
            sx={{
                width: { lg: props.drawerProps.open ? `calc(100% - ${props.drawerProps.width}px)` : '100%' },
                ml: { lg: props.drawerProps.open ? `${props.drawerProps.width}px` : '0' },
            }}
        >
            <Toolbar>
                <IconButton
                    aria-label="go back"
                    edge="start"
                    onClick={() => navigate(-1)}
                    sx={{ backgroundColor: 'rgba(0,0,0,.2)', color: '#ffe', borderRadius: 5, mr: 3 }}

                >
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.drawerProps.handleDrawerToggle}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' }, cursor: 'pointer' }} onClick={() => navigate(RouteHelper.home)}>
                    {AppConst.name}
                </Typography>
                <IconButton onClick={() => navigate(RouteHelper.perfil)} sx={{ backgroundColor: 'rgba(0,0,0,.2)', color: '#ffe', borderRadius: 5, mr: 1 }}>
                    <AccountCircle />
                </IconButton>
                <IconButton onClick={toggleColorMode} sx={{ backgroundColor: 'rgba(0,0,0,.2)', color: '#ffe', borderRadius: 5, mr: 1 }} >
                    {
                        theme.palette.mode === 'dark'
                            ? <LightModeIcon />
                            : <DarckModeIcon />
                    }
                </IconButton>
                <IconButton onClick={logOut} sx={{ backgroundColor: 'rgba(0,0,0,.2)', color: '#ffe', borderRadius: 5, mr: 3 }}>
                    <Logout />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarTop