
import RemoveIcon from '@mui/icons-material/Remove'
import { LoadingButton, Skeleton } from '@mui/lab'
import { Autocomplete, LinearProgress, Paper, Badge, Box, Button, CircularProgress, Container, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import Image from 'mui-image'
import { useEffect, useState } from 'react'
import BottomPanel from '../../components/bottom_panel'
import NumberChanger from '../../components/number_changer'
import { ApiConstants } from '../../constants/api_constants'
import { IGetProdutResponse } from '../../shared/services/models/product'
import productRepository from '../../shared/services/repositories/product_repository'
import SellDrawer from './sell_drawer'
import { IPayementMethod } from '../../shared/services/models/payment-method'
import pymethodsRepository from '../../shared/services/repositories/payment_method_repository'
import saleRepository from '../../shared/services/repositories/sale_repository'
import { IPostProducts, IPostSaleRequest } from '../../shared/services/models/sale'
import { FormatNumber } from '../../helpers/formats'
import { useAuth } from '../../shared/hooks/useAuth'

export interface ISelectedProduct {
  id: number,
  sku: string,
  description: string,
  price: number,
  totalPrice: number,
  selectedQty: number
  img?: string,
}

const PDVPage = () => {

  const [quantity, setQuantity] = useState<number>(0)
  const [product, setProduct] = useState<IGetProdutResponse | null>(null)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<IGetProdutResponse | null>(null)
  const [products, setProducts] = useState<IGetProdutResponse[]>([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [selectedProducts, setSelectedProducts] = useState<ISelectedProduct[]>([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [open, setOpen] = useState(false);
  const auth = useAuth()

  const [pyMethods, setPyMethods] = useState<IPayementMethod[]>([])
  const [loadingPyMethods, setLoadingPayMethods] = useState(true)

  const [loadingSave, setLoadingSave] = useState(false)

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    productRepository
      .getAll(0, true, undefined, 1)
      .then(res => {
        setProducts(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingProducts(false)
      })

    pymethodsRepository
      .getAll()
      .then(res => {
        setPyMethods(res.data)
        console.log('payments errorr', res)
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingPayMethods(false)
      })
  }, [])

  function getProduct(id: number) {
    setLoadingProduct(true)
    productRepository
      .getById(id)
      .then(res => {
        setProduct(res.data)
        setQuantity(0)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingProduct(false)
      })
  }

  function addToSelectedProducts() {

    const exists = selectedProducts.find(e => e.id === selectedProduct?.id)

    const newSP = {
      id: product?.id as number,
      description: product?.description as string,
      img: JSON.parse(product?.imgs_url || '[]')[0],
      price: product?.price as number,
      selectedQty: quantity,
      sku: product?.sku as string,
      totalPrice: (product?.price as number) * quantity
    }

    if (exists) {
      const newSPs = selectedProducts.map(p => {
        if (p.id === newSP.id)
          return newSP
        return p;
      })
      setSelectedProducts(old => newSPs)
      calculateTotalPrice(newSPs)
    } else {
      setSelectedProducts(old => [...old, newSP])
      calculateTotalPrice([...selectedProducts, newSP])
    }

    cleanFields()
  }

  function removeOfSelectedProducts(id: number) {
    const newSPs = selectedProducts.filter(p => p.id !== id)
    setSelectedProducts(old => newSPs)
    calculateTotalPrice(newSPs)
  }

  function cleanFields() {
    setProduct(old => null)
    setSelectedProduct(old => null)
    setQuantity(old => 0)
  }

  function cleanAllFields() {
    cleanFields()
    setTotalPrice(old => 0)
    setSelectedProducts(old => [])
  }


  function calculateTotalPrice(products: ISelectedProduct[]) {
    let tP = 0
    products.forEach(p => {
      tP = tP + p.totalPrice
    })
    setTotalPrice(old => tP)
  }


  function handleSubmit(payMethod: number): void {

    const data: IPostSaleRequest = {
      origin: "LOCAL",
      company_id: auth.auth?.user?.company_id ?? 0,
      py_id: payMethod,
      client_code: 'INTERNO',
      
      products: getProductsModel()
    }

    setLoadingSave(true)

    saleRepository
      .store(data)
      .then(res => {
        cleanAllFields()
        setOpen(false)
      })
      .catch(err => {
        alert(err)
        console.log(err)
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  function getProductsModel() {
    var res = selectedProducts.map(sp => ({
      price: sp.price,
      qty: sp.selectedQty,
      product_id: sp.id,
    }))

    return res
  }

  function handleCancel(): void {
    cleanAllFields()
    setOpen(false)
  }

  return (
    <>
      <Container sx={{ marginTop: 2, marginBottom: 200 }}>
        <Stack spacing={1}>
          <Typography variant='h5'>
            PDV
          </Typography>
          <Autocomplete
            disablePortal
            value={selectedProduct}
            onChange={(e, product) => { setSelectedProduct(product); getProduct(product?.id as number) }}
            getOptionLabel={product => (product.description as string)}
            id="combo-box-demo"
            options={products}
            loading={loadingProducts}
            onLoad={(e) => <LinearProgress color='success' />}
            sx={{ maxWidth: 600 }}
            renderInput={(params) => <TextField {...params} label="Pesquisar" />}
          />
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={4} >

            {
              loadingProduct ?
                <Paper sx={{ marginTop: 2, minHeight: '300px' }}>
                  <LinearProgress color='success' />

                </Paper>
                : product ?
                  <Paper sx={{ marginTop: 2, padding: 2 }}>
                    <Stack direction={'column'} spacing={2}>

                      <Stack
                        direction={'column'}
                        alignItems={'center'}
                      >

                      </Stack>

                      <Table size='small'>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>
                              <Typography flexGrow={2} variant='h5'>{product.description}<Badge color='info'>{product.qty}</Badge></Typography>
                            </TableCell>
                            <TableCell align='right'>
                              <Box
                                border={'1px dashed'}
                                sx={{ width: 60, height: 60 }}
                              >
                                <Image style={{ flexGrow: 1, maxWidth: 60 }} src={ApiConstants.baseUrlFiles + JSON.parse(product?.imgs_url || '[]')[0]} />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell >Código(sku)</TableCell>
                            <TableCell align='right'>{product.sku}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Quantidade
                              {
                                product.qty && product.qty > 0 ?
                                  <Typography color={'blue'} variant='body2'>
                                    Min: {product.minimum_stock}
                                  </Typography>
                                  :
                                  <Typography color={'red'} variant='body2'>
                                    O estoque esta vázio
                                  </Typography>
                              }
                            </TableCell>
                            <TableCell align='right'>
                              <Stack>
                                <NumberChanger
                                  value={quantity}
                                  setValue={setQuantity}
                                  min={product?.minimum_stock as number}
                                  max={1000}
                                  disabled={(product?.qty || 0) < 1}
                                />


                              </Stack>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Preço unitário:</TableCell>
                            <TableCell align='right'>{FormatNumber.price(product.price as number)} MZN</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell >Preço total:</TableCell>
                            <TableCell align='right'> {FormatNumber.price((product?.price || 0) * quantity)} MZN</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                      <Stack direction={'row'} spacing={2}>
                        <Button
                          variant='text'
                          color='warning'
                          onClick={cleanFields}

                        >
                          Cancelar
                        </Button>
                        <LoadingButton
                          variant='contained'
                          color='warning'
                          onClick={addToSelectedProducts}
                          disabled={((product.qty || 0) < 1 || quantity < 1)}
                        >
                          Adicionar
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Paper>
                  :
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>
                      Selecione um producto, e tente novamente
                    </Typography>
                  </Box>
            }
          </Grid>
          <Grid item xs={8} justifyContent={'start'} >
            <TableContainer sx={{ borderLeft: '3px solid grey', maxHeight: 500 }} >
              <Table sx={{ minWidth: 400 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">Descrição</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Preço unitário</TableCell>
                    <TableCell align="right">Preço Total</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProducts.map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell component="th" scope="row">
                        <Image src={ApiConstants.baseUrlFiles + row.img} style={{ maxWidth: 100 }} />
                      </TableCell>
                      <TableCell align="right">{row.description}</TableCell>
                      <TableCell align="right">{row.selectedQty}</TableCell>
                      <TableCell align="right">{FormatNumber.price(row.price)} MZN</TableCell>
                      <TableCell align="right">{FormatNumber.price(row.totalPrice)} MZN</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => removeOfSelectedProducts(row.id)}>
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      <BottomPanel>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={2}
        >
          <Button
            variant='text'
            size='large'
            onClick={cleanAllFields}
            disabled={selectedProducts.length < 1}
          >
            Cancelar Venda
          </Button>
          <Button
            variant='contained'
            size='large'
            disabled={selectedProducts.length < 1}
            onClick={() => handleDrawerToggle()}
          >
            Finalizar Venda
          </Button>

          <Stack sx={{ marginLeft: '2rem' }}>
            <Typography variant='body1' color='grey'>Preço Total</Typography>
            <Stack direction={'row'} spacing={1}>
              <Typography variant='body2' fontWeight={550}>MZN</Typography>
              <Typography variant='h4' fontWeight={600}>{FormatNumber.price(totalPrice)}</Typography>
            </Stack>
          </Stack>



        </Stack>
      </BottomPanel>
      <SellDrawer
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        totalPrice={totalPrice}
        pyMethods={pyMethods}
        products={selectedProducts}
        loadingSave={loadingSave}
        loadingPayments={loadingPyMethods}
        setPyMethod={setPyMethods}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </>
  )
}

export default PDVPage