import React, { useEffect, useState } from 'react'
import TopProductsChart from '../../components/top_product_chart';
import SalesByLocationChart from '../../components/sale_by_location_chart';
import SalesByMonthChart from '../../components/sales_by_months';
import DateFilter from '../../components/date_filter2';
import { Box, Grid, Typography } from '@mui/material';
import { Stack } from 'rsuite';
import { LineChart } from 'recharts';
import { Interface } from 'readline';
import saleRepository from '../../shared/services/repositories/sale_repository';
import { IMonthlySales, ISalesByOriginChart, ITopProductsCharts } from '../../shared/services/models/chart';


const data = {

  topProducts: [
    { product: 'Arroz mariana', sales: 43 },
    { product: 'Massa bela', sales: 45 },
    { product: 'Massa Mariana', sales: 35 },
    { product: 'Açucar castanho', sales: 27 },
    { product: 'Açucar branco', sales: 13 },
    { product: 'Mayone 200g', sales: 21 },
    { product: 'Arroz Assasuna 25kg', sales: 18 },
    { product: 'Caldo', sales: 3 },
  ],

  salesByLocation: [
    { name: 'Marketplace', sales: 700 },
    { name: 'Local', sales: 300 },
  ],

  salesByMonth: [
    { month: '2023-01', sales: 400 },
    { month: '2023-02', sales: 300 },
    { month: '2023-03', sales: 200 },
    { month: '2023-04', sales: 278 },
    { month: '2023-05', sales: 189 },
    { month: '2023-06', sales: 239 },
    { month: '2023-07', sales: 349 },
    { month: '2023-08', sales: 200 },
    { month: '2023-09', sales: 300 },
    { month: '2023-10', sales: 400 },
    { month: '2023-11', sales: 278 },
    { month: '2023-12', sales: 189 },
  ],

};




const HomePage: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date('2023-01-01'));
  const [endDate, setEndDate] = useState<Date | null>(new Date('2023-12-31'));

  const [loadingMonthlySales, setLoadingMonthlySales] = useState<boolean>(false)
  const [monthlySales, setMonthlySales] = useState<IMonthlySales[]>([])

  const [loadingTopProducts, setLoadingTopProducts] = useState<boolean>(false)
  const [topProducts, setTopProducts] = useState<ITopProductsCharts[]>([])

  const [loadingSalesByOriginChart, setLoadingSalesByOriginChart] = useState<boolean>(false)
  const [salesByOriginChart, setSalesByOriginChart] = useState<ISalesByOriginChart[]>([])

  const filteredData = {
    topProducts: data.topProducts,
    salesByLocation: data.salesByLocation,
    salesByMonth: data.salesByMonth.filter(
      (item) =>
        new Date(item.month).getTime() >= (startDate ? startDate.getTime() : 0) &&
        new Date(item.month).getTime() <= (endDate ? endDate.getTime() : Infinity)
    ),
  };

  useEffect(() => {
    getMonthlySales()
    getTopProducts()
    getSalesByOriginChart()
}, [])


  function getMonthlySales() {
    setLoadingMonthlySales(true)
    saleRepository
      .getMonthlySales()
      .then(res => {
        console.log(res)
        setMonthlySales(res.data)
      })
      .catch(err => {
        alert(err)
        console.log(err)
      })
      .finally(() => {
        setLoadingMonthlySales(false)
      })
  }

  function getTopProducts() {
    setLoadingTopProducts(true)
    saleRepository
      .getTopProducts()
      .then(res => {
        console.log(res)
        setTopProducts(res.data)
      })
      .catch(err => {
        alert(err)
        console.log(err)
      })
      .finally(() => {
        setLoadingTopProducts(false)
      })
  }

  function getSalesByOriginChart() {
    setLoadingSalesByOriginChart(true)
    saleRepository
      .getSalesByOriginChart()
      .then(res => {
        console.log(res)
        setSalesByOriginChart(res.data)
      })
      .catch(err => {
        alert(err)
        console.log(err)
      })
      .finally(() => {
        setLoadingSalesByOriginChart(false)
      })
  }

  return (
    <div>
      <Box mx={8} >
        <Stack spacing={20} direction='row'>
          <h1>Dashboard</h1>
          {/* <DateFilter /> */}
        </Stack>
      </Box>
      <Grid container>
        <Grid item lg={12}>
          <Typography fontWeight={520} ml={8}>
            Vendas por Mês
          </Typography>
          <SalesByMonthChart data={monthlySales} />
        </Grid>
        <Grid item lg={8}>
          <Typography fontWeight={520} ml={8}>
            Produtos mais vendidos
          </Typography>
          <TopProductsChart data={topProducts} />
        </Grid>
        <Grid item lg={4}>
          <Typography fontWeight={520} ml={8}>
            Vendas por local
          </Typography>
          <SalesByLocationChart data={salesByOriginChart} />
        </Grid>

      </Grid>
    </div>
  );
};


export default HomePage