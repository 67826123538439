import { Box } from '@mui/material'
import React from 'react'
import splashImage from '../../assets/guevaicon.png'

const SplashScrean = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center', // Centraliza horizontalmente
                alignItems: 'center', // Centraliza verticalmente
                height: '100vh', // Altura da tela inteira
                // backgroundColor: '#f0f0f0', // Cor de fundo, pode alterar se quiser
            }}
        >
            <img
                src={splashImage}
                alt="Centralized"
                style={{ maxWidth: '20rem', height: 'auto' }}
            />
        </Box>
    )
}

export default SplashScrean