import { stat } from "fs"
import axiosInstance from "../../api/api_client"
import { IPostProductRequest } from "../models/product"
import { IPostSaleRequest, IPutSaleStatusRequisition } from "../models/sale"
import { ApiConstants } from "../../../constants/api_constants"

const getAll = async (filters: { search: string, origin: string }, status?: string, page?: number) => {
    await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: ApiConstants.baseUrl })
    let response = await axiosInstance.get(`/sales?${status ? 'status=' + status + '&' : ''}${page ? 'page=' + page + '&' : ''}search=${filters.search}&origin=${filters.origin}`)
    return response
}

const getByCode = async (code: string) => {
    let response = await axiosInstance.get(`/sales/${code}`)
    return response
}

const getProducts = async (code: string) => {
    let response = await axiosInstance.get(`/sales/${code}/products`)
    return response
}

const getMonthlySales = async () => {
    let response = await axiosInstance.get(`/sales/monthlySales`)
    return response
}

const getTopProducts = async () => {
    let response = await axiosInstance.get(`/sales/topProductsCharts`)
    return response
}

const getSalesByOriginChart = async () => {
    let response = await axiosInstance.get(`/sales/salesByOriginChart`)
    return response
}

const store = async (data: IPostSaleRequest) => {
    let response = await axiosInstance.post('/sales', data)
    return response
}

const updateStatus = async (code: string, data: IPutSaleStatusRequisition) => {
    let response = await axiosInstance.put(`/sales/${code}/status`, data)
    return response
}




const saleRepository = {
    getAll,
    getByCode,
    getProducts,
    store,
    updateStatus,
    getMonthlySales,
    getTopProducts,
    getSalesByOriginChart
}

export default saleRepository