import { Route, Routes } from "react-router-dom"
import { RouteHelper } from "../helpers/route_helper"
import MainLayout from "../layouts/main"
import AddProductsPage from "../pages/add_products"
import HomePage from "../pages/home"
import InitialPage from "../pages/initial"
import LoginPage from "../pages/login"
import PDVPage from "../pages/pdv"
import ProductsPage from "../pages/products"
import SalePage from "../pages/sale"
import SalesPage from "../pages/sales"
import CheckStockPage from "../pages/stock"
import CheckStockProductPage from "../pages/stock_product"
import { useAuth } from "../shared/hooks/useAuth"
import { Authetication, Unautheticated } from "./auth"
import SellerPage from "../pages/sellers"
import RegisterPage from "../pages/register"
import CompanyPage from "../pages/company"
import IntegrationPage from "../pages/integration"
import PerfilPage from "../pages/perfil"


const AppRoutes = () => {

    const { auth } = useAuth()


    return (
        <Routes>

            <Route element={<Unautheticated user={auth?.user} />} >
                <Route path={RouteHelper.login} element={<LoginPage />} />
                <Route path={RouteHelper.register} element={<RegisterPage />} />
            </Route>

            <Route element={<Authetication user={auth?.user} role={[]} />} >
                <Route element={<MainLayout />}>
                    <Route path={RouteHelper.initialPage} element={<HomePage />} />
                    <Route path={RouteHelper.sellers} element={<SellerPage />} />
                    <Route path={RouteHelper.home} element={<HomePage />} />
                    <Route path={RouteHelper.pdv} element={<PDVPage />} />
                    <Route path={RouteHelper.products} element={<ProductsPage />} />
                    <Route path={RouteHelper.addProducts} element={<AddProductsPage />} />
                    <Route path={RouteHelper.updateProductrel} element={<AddProductsPage isEditPage />} />
                    <Route path={RouteHelper.checkStock} element={<CheckStockPage />} />
                    <Route path={RouteHelper.productCheckStockrel} element={<CheckStockProductPage />} />
                    <Route path={RouteHelper.sales} element={<SalesPage />} />
                    <Route path={RouteHelper.saleRel} element={<SalePage />} />
                    <Route path={RouteHelper.saleRel} element={<CompanyPage />} />
                    <Route path={RouteHelper.integation} element={<IntegrationPage />} />
                    <Route path={RouteHelper.perfil} element={<PerfilPage />} />
                </Route>
            </Route>
        </Routes >
    )
}

export default AppRoutes